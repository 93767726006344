@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .provider-profile-div {
    @apply bg-white py-6 px-6 shadow-provider-proifle rounded-lg;
  }

  .box {
    @apply border border-solid border-black;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #333B7A;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #333B7A;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.6);
}

