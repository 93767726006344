.App {
  text-align: left;
  width: 100%;
}
img {
  width: 100%;
}
body {
  font-family: 'Poppins', sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}


/* .active {
  color: white;
  background-color: #333B7A;
} */
/* .inactive {
  color: black;
  background-color: white;
} */
button:disabled {
  opacity: 0.3;
}
.default-checkbox:checked {
  background-color: #014f86;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
input:-internal-autofill-selected {
  background-color: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0px;
}
.loginBoxShadow {
  background: #ffffff;
  /* shadow/md */

  box-shadow: 0px 4px 8px -2px rgba(16, 40, 36, 0.1),
    0px 2px 4px -2px rgba(16, 40, 36, 0.06);
}
.searchBoxShadow {
  box-shadow: 0px 1px 3px rgba(16, 40, 36, 0.1),
    0px 1px 2px rgba(16, 40, 36, 0.06);
}

.tableBoxShdow {
  background: #ffffff;
  /* shadow/md */

  box-shadow: 0px 4px 8px -2px rgba(16, 40, 36, 0.1),
    0px 2px 4px -2px rgba(16, 40, 36, 0.06);
  border-radius: 8px;
}
.header {
  grid-area: header;
  background: #333B7A;
  /* border-bottom: 1px solid #d1d5db; */
}
.css-c5ma87-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 8px;

}


.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding:13px;
  
}


.content {
  grid-area: content;
}
.g-container {
  overflow-x: hidden;
  height: 100vh;
  grid-template-rows: 96px auto;
  /* grid-template-columns: 250px auto; */
  background-color: #fcfcfd;

  grid-template-areas:
    'header  header '
    'content content ';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ECF6FF inset !important;
}
.pac-container {
  z-index: 1500;
}
